<template>
  <div class="w1092">
    <div class="moneyread">
      <!--h3><img src="@/assets/img/mypage_point_waring.png" />{{$t('front.cash.moneyCheckList')}}</h3-->
      <ul>
        <li><!--img src="@/assets/img/icon_check.png" /-->{{$t('front.cash.moneyCheckListA')}}</li>
        <li><!--img src="@/assets/img/icon_check.png" /-->{{$t('front.cash.moneyCheckListB')}}</li>
        <li><!--img src="@/assets/img/icon_check.png" /-->{{$t('front.cash.moneyCheckListC')}}</li>
        <li><!--img src="@/assets/img/icon_check.png" /-->{{$t('front.cash.moneyCheckListD')}}</li>
      </ul>
    </div>
    <div class="moneyinfo">
     <!--h3 class="applih">{{$t('front.cash.moneyCheckListE')}}</h3>
     <p class="applip">{{$t('front.cash.moneyCheckListF')}}</p-->
     <ul class="applistep">
        <li><img src="@/assets/img/icon_checks.svg" /></li>
        <li><em>{{$t('front.cash.moneyCheckListH1')}}</em><br />{{$t('front.cash.moneyCheckListI')}}{{$t('front.cash.moneyCheckListJ')}}{{$t('front.cash.moneyCheckListK')}}{{$t('front.cash.moneyCheckListL')}}{{$t('front.cash.moneyCheckListM')}}</li>
     </ul>
     <div class="applibtnWrap">
         <a class="applibtn" @click="getAcc">{{$t('front.cash.moneyCheckListG')}}({{$t('front.cash.moneyCheckListG_1')}})</a>
         <!--a class="applibtn" @click="getCoinInfo">{{$t('front.cash.coinCharge')}}</a-->
     </div>
    </div>
    <div class="moneyinfoappli">
      <div class="appliinputwrap">
        <div>
           <ul class="appliinput">
             <li>{{$t('front.cash.moneyHave')}}</li>
             <li class="flexstart"><span class="b w200">{{thousand(userData.cashAmt)}}</span> {{$t('front.common.money')}}</li>
           </ul>
           <ul class="appliinput">
             <li>{{$t('front.cash.moneyInput')}}</li>
             <li class="flexstart"><input type="text" pattern="[0-9.,]+" class="numb w200" :placeholder="$t('front.cash.inputNumber')" v-model="setCurrentChargeMoney" :readonly="true" @focus="onFocus">{{$t('front.common.money')}}</li>
           </ul>
           <ul class="moneybtnwrap">
             <li class="one"><a @click="setMoney(10000)">1{{$t('front.cash.manwon')}}</a></li>
             <li class="one"><a @click="setMoney(30000)">3{{$t('front.cash.manwon')}}</a></li>
             <li class="one"><a @click="setMoney(50000)">5{{$t('front.cash.manwon')}}</a></li>
             <li class="two"><a @click="setMoney(100000)">10{{$t('front.cash.manwon')}}</a></li>
             <li class="two"><a @click="setMoney(300000)">30{{$t('front.cash.manwon')}}</a></li>
             <li class="two"><a @click="setMoney(500000)">50{{$t('front.cash.manwon')}}</a></li>
             <li class="thr"><a @click="setMoney(1000000)">100{{$t('front.cash.manwon')}}</a></li>
             <li class="four"><a @click="setMoney(0)">{{$t('front.cash.correct')}}</a></li>
           </ul>
        </div>
        <div>
           <ul class="appliresult">
              <li>
                 <span>{{$t('front.cash.balance')}}</span>
                 <span><em>{{thousand(userData.cashAmt)}}</em> {{$t('front.common.money')}}</span>
              </li>
              <li>
                 <span>{{$t('front.cash.deposit')}}</span>
                 <span><em>{{setCurrentChargeMoney}}</em> {{$t('front.common.money')}}</span>
              </li>
              <li>
                 <span>{{$t('front.cash.BaD')}}</span>
                 <span><em>{{thousand(userData.cashAmt)}}</em> {{$t('front.common.money')}}</span>
              </li>
           </ul>
        </div>
        <!--ul class="appliinput">
          <li>보너스 금액</li>
          <li><span class="pp b w110">{{thousand(bonus)}}</span> 원</li>
        </ul>
        <ul class="appliinput">
          <li>보너스 혜택</li>
          <li class="moneybtnwrap">
            <a class="fiv" :class="{ on: isApplyBonus }" @click="isApplyBonus=!isApplyBonus" >보너스 받기</a>
            <a class="fiv" :class="{ on: !isApplyBonus }" @click="isApplyBonus=!isApplyBonus" >보너스 받지않기</a>
          </li>
        </ul-->

      </div>
      <div class="applibtns mt-40">
        <a @click="onSubmit('pc')">{{$t('front.cash.moneyCheckListL')}}</a>
      </div>
    </div>

    <div class="board">
      <ul class="boardw first">
        <li class="">{{$t('front.board.applyDay')}}</li>
        <li class="">{{$t('front.board.processMoney')}}</li>
        <li class="">{{$t('front.board.processBonus')}}</li>
        <li class="">{{$t('front.board.processDay')}}</li>
        <li class="">{{$t('front.board.processState')}}</li>
      </ul>
      <template v-if="cashList.length > 0">
        <ul class="boardw" v-for="item in cashList" v-bind:key="item.cashIdx">
          <li class="">{{dateFormat(item.regDate)}}</li>
          <li class="">{{thousand(item.cashAmt)}}</li>
          <li class="">0</li>
          <li class="">{{dateFormat(item.updDate)}}</li>
          <li class=""><span :class="{'bl' : item.cashStatus !== '-1'}">{{computedCashStatus(item.cashStatus).text}}</span></li>
        </ul>
      </template>
      <template v-else>
        <ul class="boardw">
          <li>{{$t('front.common.notFoundList')}}</li>
        </ul>
      </template>
    </div>
    <div class="boardpage mt20 mb20">
      <pagination :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  @goToPage="onCashListSearch" />
    </div>

    <form name=CoinwikiForm action="https://www.coinwiki.shop/newmatching/" target="" method="post">
      <input type=hidden name="groupid" :value="'stca1'">
      <input type=hidden name="groupkey" :value="'z0NEQAzwd3xXYvd7'">
      <input type=hidden name="groupuserid" :value="userData.memId">
      <input type=hidden name="groupusername" :value="userData.memName">
      <input type=hidden name="groupstr1">
      <input type=hidden name="groupstr2">
      <input type=hidden name="returntrueurl">
      <input type=hidden name="returnfalseurl">
    </form>
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import { getDateStr, getSubDaysDate, thousand } from '@/libs/utils'
import { cashDelete, cashIn } from '@/api/cash'

import store from '@/store'
import { mapState } from 'vuex'
export default {
  name: 'Charge',
  computed: {
    ...mapState([
      'userData'
    ])
  },
  components: {
    Pagination
  },
  watch: {
    type: function () {
      // if (this.type === 'list') {
      this.onCashListSearch()
      // }
    },
    setCurrentChargeMoneyStr () {
      // this.setCurrentChargeMoneyStr = thousand(this.setCurrentChargeMoneyStr)
    },
    setCurrentChargeMoney () {
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
      const parts = this.setCurrentChargeMoney.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      // use this for numeric calculations
      console.log('number for calculations: ', calcNum)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.setCurrentChargeMoney = n
    }
  },
  created () {
    this.onCashListSearch()
  },
  data () {
    return {
      type: 'apply',
      setCurrentChargeMoney: '',
      isApplyBonus: false,
      bonus: 0,
      cashList: [],
      allChecked: false,
      cashType: 'in',
      searchDate: {
        startDate: getDateStr(getSubDaysDate(new Date(), 2), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd 23:59:59')
      },
      isProcessing: false
    }
  },
  methods: {
    getCoinInfo () {
      window.open('', 'coinwikiwindows', 'width=1200,height=900,toolbar=no,directories=no,status=no, scrollbars=yes,resizable=yes,copyhistory=no')

      document.CoinwikiForm.action = 'https://www.coinwiki.shop/newmatching/'
      document.CoinwikiForm.target = 'coinwikiwindows'
      document.CoinwikiForm.groupid.value = 'stca1' // 매장아이디 (필수입력) -
      document.CoinwikiForm.groupkey.value = 'z0NEQAzwd3xXYvd7' // 매장 키값 (필수입력) - 16자리
      document.CoinwikiForm.groupuserid.value = this.userData.memId // 매장 회원아이디 (필수입력)
      document.CoinwikiForm.groupusername.value = this.userData.memName // 매장 회원이름 (필수입력)
      document.CoinwikiForm.groupstr1.value = '' // 매장에서 사용하는 값 (100자 이 내)
      document.CoinwikiForm.groupstr2.value = '' // 매장에서 사용하는 값2 (100자 이 내)
      document.CoinwikiForm.returntrueurl.value = '' // 비상시 이용 – 정상시 불필요 document.CoinwikiForm.returnfalseurl.value = ""; //비상시 이용 – 정상시 불필요
      document.CoinwikiForm.submit()

      // if (isMobile()) {
      //   window.open('/coinwiki', 'coinwikiwindows')
      // } else {
      //   window.open('/coinwiki', 'coinwikiwindows', 'width=1200,height=900,toolbar=no,directories=no,status=no, scrollbars=yes,resizable=yes,copyhistory=no')
      // }
    },
    async getAcc () {
      const title = '계좌문의입니다.'
      const content = '계좌문의입니다.'
      const params = {
        title: title,
        content: content,
        boardType: 'faq',
        category: '',
        faqType: 'bankaccount'
      }

      await this.setSaveAccountBoard(params, async function () {
        await this.loadList()
      }.bind(this))
    },
    async onRemoveMsg () {
      let checkedCount = 0
      this.cashList.forEach(item => {
        if (item.checked) {
          checkedCount++
        }
      })
      if (checkedCount <= 0) {
        this.onCheck('front.cash.noSelectMessage')
        return false
      }
      const confirm = await this.onConfirm('front.cash.confirmDeleteMessage')

      if (confirm) {
        for (let i = 0, iLen = this.cashList.length; i < iLen; i++) {
          const item = this.cashList[i]
          if (item.checked) {
            cashDelete(item).then(response => {
            })
          }
        }
        this.onCheck('front.cash.completeDeleteMessage')
        this.onCashListSearch()
      }
    },
    setMoney (value) {
      let money = Number(this.setCurrentChargeMoney.replace(/,/g, ''))
      if (value) {
        money += value
      } else {
        money = value
      }
      this.setCurrentChargeMoney = thousand(money)
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
    },
    async onSubmit (device = 'mobile') {
      if (this.setCurrentChargeMoney && this.setCurrentChargeMoney !== '0') {
        const confirm = await this.onConfirm('front.cash.confirmCharge')

        if (confirm) {
          if (!this.isProcessing) {
            this.isProcessing = true
            this.emitter.emit('Loading', true)

            const params = {
              memId: this.userData.memId,
              cashType: 1, // in,
              cashStatus: 'in',
              cashAmt: this.setCurrentChargeMoney.replace(/,/g, '')
            }

            cashIn(params).then(response => {
              store.dispatch('storeUserData').then(userData => {

              }).then(async () => {
                console.log(response)
                const result = response.data

                if (result.resultCode === '0') {
                  this.emitter.emit('Loading', false)
                  const confirm = await this.onCheck('front.cash.completeCharge')
                  if (confirm) {
                    if (device === 'mobile') {
                      this.replacePageByName('my')
                    } else {
                      location.reload()
                    }
                  }
                } else {
                  this.emitter.emit('Loading', false)
                  const confirm = await this.onCheck('api.' + result.resultCode)
                  if (confirm) {
                    if (device === 'mobile') {
                      this.replacePageByName('my')
                    } else {
                      location.reload()
                    }
                  }
                }
              })
            }).catch(err => {
              this.emitter.emit('Loading', false)
              console.error(err)
            })
          }
        }
      } else {
        this.onAlert('warningart', 'front.cash.emptyPrice')
      }
    }
  }
}
</script>
<style scoped>
.numb{ text-align: left !important; width: 180px; }
</style>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
